import { Component, OnInit } from '@angular/core';
import { WorkOrderStatus } from '../../enums/work-orders';
import { WorkOrdersService } from '../../services/work-orders/work-orders.service';
import { firstValueFrom, pairwise, startWith } from 'rxjs';
import { ContractorDetails } from '../contractor-sign-up/contractor-sign-up.component';
import { Router } from '@angular/router';
import {
    AuthServiceService,
    UserGroups,
} from '../../services/auth-service/auth-service.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { startCase } from 'lodash';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';

export interface WorkOrder {
    site: string;
    orderNumber: string;
    dateCreated: string;
    status: WorkOrderStatus;
    contractorId: Partial<ContractorDetails>;
    expenseCategory: string;
    leaseGroups: { [key: string]: number };
    description: string;
    risks: string[];
    audits?: { date: string; event: string; user: string }[];
}

@Component({
    selector: 'app-work-orders',
    templateUrl: './work-orders.component.html',
    styleUrls: ['./work-orders.component.css'],
})
export class WorkOrdersComponent implements OnInit {
    workOrders: WorkOrder[] = [];
    filteredOrders: WorkOrder[] = [];
    orderFilter!: string;
    loading: boolean = true;
    userGroups: (string | number)[] = [];
    authLimit!: number;
    createdYears: number[] = new Array(new Date().getFullYear() - 2022 + 1)
        .fill(0)
        .map((val, i) => i + 2022)
        .sort((a, b) => b - a);
    statusOptions = Object.values(WorkOrderStatus);
    filterForm = new FormGroup({
        contractor: new FormControl(''),
        expenseCategory: new FormControl(''),
        status: new FormArray([
            ...this.statusOptions.map(() => new FormControl(true)),
        ]),
        yearCreated: new FormArray([
            ...this.createdYears.map((val, i) => new FormControl(i <= 1)),
        ]),
    });
    filtering: boolean = false;

    contractors: string[] = [];
    expenseCats: string[][] = [];

    constructor(
        public workOrderService: WorkOrdersService,
        public router: Router,
        public auth: AuthServiceService,
        public config: AppConfigService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            this.workOrders = (
                await firstValueFrom(
                    this.workOrderService.getAllOrders(
                        this.createdYears.slice(0, 2)
                    )
                )
            ).sort((a, b) => b?.orderNumber?.localeCompare(a?.orderNumber!));

            this.filteredOrders = [...this.workOrders];

            this.contractors = [
                ...new Set(
                    this.workOrders
                        .map((order) => order.contractorId.companyName!)
                        .sort()
                ),
            ];

            if (!this.config?.config?.budgetData)
                await this.config.getConfig(this.config.selectedSite);
            const { heads } = this.config?.config?.budgetData;

            const flatHeads = Object.fromEntries(
                heads.flatMap((head) => [
                    [head.code, head.name],
                    ...(head.subCats
                        ? head.subCats.map((sub) => [sub.code, sub.name])
                        : []),
                ])
            );

            this.expenseCats = [
                ...new Set(
                    this.workOrders.map((order) => order.expenseCategory).sort()
                ),
            ]
                .map((cat) => [
                    cat,
                    cat === 'RES' ? 'RESERVES' : flatHeads[cat],
                ])
                .sort((a, b) => a[1].localeCompare(b[1]));

            this.authLimit = await this.auth.getAuthLimit();

            this.filterForm.valueChanges
                .pipe(startWith(this.filterForm.getRawValue()), pairwise())
                .subscribe(async ([prev, value]) => {
                    try {
                        this.filtering = true;
                        if (
                            prev.yearCreated?.some(
                                (val, i) => val !== value?.yearCreated?.[i]
                            )
                        ) {
                            this.workOrders = value.yearCreated?.every(
                                (year) => !year
                            )
                                ? []
                                : (
                                      await firstValueFrom(
                                          this.workOrderService.getAllOrders(
                                              this.createdYears.filter(
                                                  (year, i) =>
                                                      value?.yearCreated?.[i]
                                              )
                                          )
                                      ).catch((error) => [])
                                  ).sort((a, b) =>
                                      b!.orderNumber!.localeCompare(
                                          a!.orderNumber!
                                      )
                                  );

                            this.contractors = [
                                ...new Set(
                                    this.workOrders
                                        .map(
                                            (order) =>
                                                order.contractorId.companyName!
                                        )
                                        .sort()
                                ),
                            ];
                        }

                        this.filteredOrders = this.workOrders.filter(
                            (order) =>
                                ((!value?.contractor ||
                                    order.contractorId.companyName ===
                                        value.contractor) &&
                                    value?.status
                                        ?.map((status, index) =>
                                            status
                                                ? this.statusOptions[index]
                                                : null
                                        )
                                        .filter((status) => status)
                                        .includes(order.status) &&
                                    !value?.expenseCategory) ||
                                value?.expenseCategory === order.expenseCategory
                        );
                    } catch (error) {
                        console.error(error);
                    } finally {
                        this.filtering = false;
                    }
                });
        } catch (error) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    getTotal(workOrder: WorkOrder) {
        return Object.values(workOrder.leaseGroups)
            .reduce((a: number, b: number) => a + b, 0)
            .toFixed(2);
    }

    statusArray = () => this.filterForm.get('status') as FormArray;

    protected readonly UserGroups = UserGroups;
    protected readonly WorkOrderStatus = WorkOrderStatus;
    protected readonly startCase = startCase;
}
