<div id="container">
    <mat-toolbar>
        <h2>CW Estates</h2>
        <ng-container>
            <button
                mat-stroked-button
                [mat-menu-trigger-for]="profileMenu"
                id="accountButton"
            >
                <mat-label *ngIf="contractor?.companyName && !mobile">{{
                    contractor.companyName
                }}</mat-label>
                <mat-icon>{{ mobile ? 'menu' : 'account_circle' }}</mat-icon>
            </button>
            <mat-menu #profileMenu="matMenu">
                <button mat-menu-item disabled>
                    <mat-icon>settings</mat-icon>
                    Account Settings
                </button>
                <button mat-menu-item (click)="logOut()">
                    <mat-icon>arrow_back</mat-icon>
                    Sign Out
                </button>
            </mat-menu>
        </ng-container>
    </mat-toolbar>
    <div class="signUpFormContainer">
        <mat-label><h1>Contractor Portal</h1></mat-label>
        <div *ngIf="loading" class="loadingSpinner">
            <app-cwe-spinner></app-cwe-spinner>
        </div>

        <div *ngIf="contractor && !loading" [formGroup]="contractorForm">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-card-title class="details-header">
                        <mat-icon slot="start">work</mat-icon>
                        <mat-label>Company Details</mat-label>
                    </mat-card-title>
                </mat-expansion-panel-header>

                <mat-card-content>
                    <div *ngIf="contractorForm" class="contractorFormContainer">
                        <mat-form-field appearance="outline">
                            <mat-label>Company Name</mat-label>
                            <input formControlName="companyName" matInput />
                        </mat-form-field>
                        <small
                            *ngIf="
                                contractorForm.controls['companyName']
                                    .touched &&
                                contractorForm.controls['companyName'].invalid
                            "
                            class="errorMessage"
                            >Please enter a Company Name</small
                        >

                        <mat-form-field appearance="outline">
                            <mat-label>Contact Full Name</mat-label>
                            <input formControlName="contactName" matInput />
                        </mat-form-field>
                        <small
                            *ngIf="
                                contractorForm.controls['contactName']
                                    .touched &&
                                contractorForm.controls['contactName'].invalid
                            "
                            class="errorMessage"
                            >Please enter a Contact Name</small
                        >

                        <mat-form-field appearance="outline">
                            <mat-label>E-Mail</mat-label>
                            <input formControlName="email" matInput />
                        </mat-form-field>
                        <small
                            *ngIf="
                                contractorForm.controls['email'].touched &&
                                contractorForm.controls['email'].invalid
                            "
                            class="errorMessage"
                            >Please enter a valid Email Address</small
                        >

                        <mat-form-field appearance="outline">
                            <mat-label>Phone Number</mat-label>
                            <input formControlName="phone" matInput />
                        </mat-form-field>
                        <small
                            *ngIf="
                                contractorForm.controls['phone'].touched &&
                                contractorForm.controls['phone'].invalid
                            "
                            class="errorMessage"
                            >Please enter a valid Phone Number</small
                        >

                        <mat-form-field appearance="outline">
                            <mat-label>Address Line 1</mat-label>
                            <input formControlName="addressOne" matInput />
                        </mat-form-field>
                        <small
                            *ngIf="
                                contractorForm.controls['addressOne'].touched &&
                                contractorForm.controls['addressOne'].invalid
                            "
                            class="errorMessage"
                            >Please enter a valid Address Line One</small
                        >

                        <mat-form-field appearance="outline">
                            <mat-label>Address Line 2</mat-label>
                            <input formControlName="addressTwo" matInput />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Address Line 3</mat-label>
                            <input formControlName="addressThree" matInput />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Post Code</mat-label>
                            <input
                                class="upperCase"
                                formControlName="postcode"
                                matInput
                            />
                        </mat-form-field>
                        <small
                            *ngIf="
                                contractorForm.controls['postcode'].touched &&
                                contractorForm.controls['postcode'].invalid
                            "
                            class="errorMessage"
                            >Please enter a valid Postcode</small
                        >
                    </div>
                </mat-card-content>
            </mat-expansion-panel>

            <mat-expansion-panel class="details">
                <mat-expansion-panel-header class="details-header">
                    <mat-card-title>
                        <mat-icon slot="start">payments</mat-icon>
                        <mat-label>Payment Details</mat-label>
                    </mat-card-title>
                </mat-expansion-panel-header>
                <mat-form-field appearance="outline">
                    <mat-label>Bank Name</mat-label>
                    <input formControlName="bankName" matInput />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['bankName'].touched &&
                        contractorForm.controls['bankName'].invalid
                    "
                    class="errorMessage"
                    >Please enter a valid Bank Name</small
                >

                <mat-form-field appearance="outline">
                    <mat-label>Account Name</mat-label>
                    <input formControlName="accountName" matInput />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['accountName'].touched &&
                        contractorForm.controls['accountName'].invalid
                    "
                    class="errorMessage"
                    >Please enter a valid Account Name</small
                >

                <mat-form-field appearance="outline">
                    <mat-label>Sort Code</mat-label>
                    <input formControlName="sortCode" matInput />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['sortCode'].touched &&
                        contractorForm.controls['sortCode'].invalid
                    "
                    class="errorMessage"
                    >Please enter a valid Sort Code</small
                >

                <mat-form-field appearance="outline">
                    <mat-label>Account Number</mat-label>
                    <input formControlName="accountNumber" matInput />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['accountNumber'].touched &&
                        contractorForm.controls['accountNumber'].invalid
                    "
                    class="errorMessage"
                    >Please enter a valid Account Number</small
                >
            </mat-expansion-panel>

            <mat-expansion-panel class="details">
                <mat-expansion-panel-header class="details-header">
                    <mat-card-title>
                        <mat-icon slot="start">folder</mat-icon>
                        <mat-label>Files</mat-label>
                    </mat-card-title>
                </mat-expansion-panel-header>
                <button
                    (click)="openFileUploadModal()"
                    id="fileButton"
                    mat-flat-button
                >
                    Upload File
                </button>
                <mat-list>
                    <mat-list-item
                        *ngFor="let file of mappedFiles"
                        role="listitem"
                    >
                        <mat-icon mat-list-icon>article</mat-icon>
                        <a
                            (click)="
                                openFile(file.fileKey); $event.preventDefault()
                            "
                            class="fileAnchor"
                            href="#"
                            mat-line
                            mat-list-item
                        >
                            <mat-label class="fileAnchor" mat-line>{{
                                file.fileKey
                                    .split('@$%&')[0]
                                    .replace(applicationId + '/', '')
                            }}</mat-label>
                            <mat-label class="fileAnchor" mat-line>{{
                                file.fileKey.split('@$%&')[1]
                            }}</mat-label>
                            <mat-label mat-line
                                >Expiry:
                                {{
                                    file.expiry | date: 'dd/MM/yyyy'
                                }}</mat-label
                            >
                        </a>
                        <button
                            (click)="deleteFile(file.fileKey)"
                            class="deleteButton"
                            color="warn"
                            mat-icon-button
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header class="details-header">
                    <mat-card-title>
                        <mat-icon slot="start">mail</mat-icon>
                        <mat-label>Notification Preferences</mat-label>
                    </mat-card-title>
                </mat-expansion-panel-header>
                <mat-label>Receive Emails for the following events:</mat-label>
                <mat-list>
                    <mat-list-item>
                        <mat-checkbox formControlName="workOrderEmails">
                            New Work Order Created
                        </mat-checkbox>
                    </mat-list-item>
                </mat-list>
            </mat-expansion-panel>

            <mat-expansion-panel class="details" [expanded]="true">
                <mat-expansion-panel-header class="details-header">
                    <mat-card-title>
                        <mat-icon slot="start">receipt</mat-icon>
                        <mat-label>Open Work Orders</mat-label>
                    </mat-card-title>
                </mat-expansion-panel-header>
                <mat-card
                    (click)="openWorkOrderModal(workOrder)"
                    *ngFor="let workOrder of openWorkOrders"
                    class="workOrderDisplay"
                >
                    <mat-card-header>
                        <mat-label>#{{ workOrder.orderNumber }}</mat-label>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="listCol">
                            <small class="workOrderLabel">Date Created</small>
                            <mat-label>{{
                                workOrder.dateCreated | date: 'dd/MM/yyyy'
                            }}</mat-label>
                        </div>
                        <div class="contractorCol">
                            <small class="workOrderLabel">Status</small>
                            <mat-label>{{ workOrder.status }}</mat-label>
                        </div>
                        <div class="contractorCol">
                            <small class="workOrderLabel">Description</small>
                            <mat-label class="desc">{{
                                workOrder?.description
                            }}</mat-label>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-expansion-panel>

            <mat-expansion-panel class="details">
                <mat-expansion-panel-header class="details-header">
                    <mat-icon slot="start">receipt</mat-icon>
                    <mat-label>Closed Work Orders</mat-label>
                </mat-expansion-panel-header>
                <mat-card
                    (click)="openWorkOrderModal(workOrder)"
                    *ngFor="let workOrder of closedWorkOrders"
                    class="workOrderDisplay"
                >
                    <mat-card-header>
                        <mat-label>#{{ workOrder.orderNumber }}</mat-label>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="listCol">
                            <small class="workOrderLabel">Date Created</small>
                            <mat-label>{{
                                workOrder.dateCreated | date: 'dd/MM/yyyy'
                            }}</mat-label>
                        </div>
                        <div class="contractorCol">
                            <small class="workOrderLabel">Status</small>
                            <mat-label>{{ workOrder.status }}</mat-label>
                        </div>
                        <div class="contractorCol">
                            <small class="workOrderLabel">Description</small>
                            <mat-label class="desc">{{
                                workOrder?.description
                            }}</mat-label>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-expansion-panel>
        </div>
    </div>
    <div
        *ngIf="contractor && contractorForm && contractorForm.dirty"
        class="footer"
    >
        <button (click)="resetForm()" color="warn" mat-flat-button>
            <mat-icon class="footerBut">cancel</mat-icon>
            <mat-label>Cancel Changes</mat-label>
        </button>
        <button
            (click)="saveChanges()"
            [disabled]="contractorForm.invalid"
            id="propertySaveButton"
            mat-flat-button
        >
            <mat-icon class="footerBut">save</mat-icon>
            <mat-label>Save Changes</mat-label>
        </button>
    </div>
</div>

<mat-toolbar *ngIf="messageBanner" id="messageBanner">
    <mat-label>{{ messageText }}</mat-label>
</mat-toolbar>
