import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StorageKeys } from '../../enums/storage';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import {
    PropertyDetail,
    PropertyFilterDetail,
    Transaction,
} from '../../interfaces/property';
import { PropertyFilter } from '../../enums/property';
import { differenceInMinutes } from 'date-fns';
import { Guid } from 'guid-typescript';
import { NGXLogger } from 'ngx-logger';
import { AuditService } from '../audit/audit.service';
import { AppConfigService } from '../app-config/app-config.service';
import { StatsResponse } from 'src/app/pages/stats/stats.component';

@Injectable({
    providedIn: 'root',
})
export class PropertyDetailsService {
    reportData: PropertyDetail[] | undefined;
    reportFilteredData: PropertyDetail[] | undefined;
    reportDisplayColumns: string[] | undefined;
    lastUpdated: string = '';
    updating: boolean = false;
    apiKey = environment.apiKey;
    apiBaseUrl = environment.apiBaseUrl;
    filterDetails?: PropertyFilterDetail[];

    constructor(
        public http: HttpClient,
        public logger: NGXLogger,
        public audit: AuditService,
        public appConfig: AppConfigService
    ) {}

    callFilterEndpoint(): Observable<PropertyFilterDetail[]> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams().set('site', this.appConfig.selectedSite);
        return this.http.get<PropertyFilterDetail[]>(
            this.apiBaseUrl + '/property',
            { headers, params }
        );
    }

    callSearchEndpoint(
        name: string,
        field: 'leaseholder' | 'tenant'
    ): Observable<Partial<PropertyDetail[]>> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams()
            .append(field, name)
            .append('site', this.appConfig.selectedSite);
        return this.http.get<Partial<PropertyDetail[]>>(
            this.apiBaseUrl + '/property/search',
            { headers, params }
        );
    }

    callPropertyEndpoint(unitFull: string): Observable<PropertyDetail> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams()
            .append(PropertyFilter.UnitFull, unitFull)
            .append('site', this.appConfig.selectedSite);
        return this.http.get<PropertyDetail>(
            this.apiBaseUrl + '/property/unit',
            {
                headers,
                params,
            }
        );
    }

    callUpdatePropertyEndpoint(
        details: PropertyDetail
    ): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.put<PropertyDetail>(
            this.apiBaseUrl + '/property',
            details,
            {
                headers: headers,
                observe: 'response',
            }
        );
    }

    async getFilterDetails(): Promise<PropertyFilterDetail[]> {
        if (this.filterDetails) return this.filterDetails;
        this.filterDetails = await firstValueFrom(this.callFilterEndpoint());
        return this.filterDetails;
    }

    async getUnitSearchDetails() {
        return (await this.getFilterDetails()).flatMap((unit) => {
            return [
                {
                    value: `${unit.unit} ${unit.unitFirst}`,
                    unitFull: unit.unitFull,
                },
                ...(unit.unitSecond
                    ? [
                          {
                              value: `${unit.unit} ${unit.unitSecond}`,
                              unitFull: unit.unitFull,
                          },
                      ]
                    : []),
            ];
        });
    }

    async getUnitFilters(field: keyof PropertyFilterDetail): Promise<string[]> {
        let details = await this.getFilterDetails();
        return [...new Set(details.map((item) => item[field]).sort())];
    }

    async getUnitNumbers(
        field?: keyof PropertyFilterDetail,
        value?: string
    ): Promise<string[]> {
        let details = await this.getFilterDetails();
        if (field) details = details.filter((item) => item[field] === value);
        return [
            ...new Set(
                details
                    .map((item) => item.unit)
                    .sort((a, b) => Number(a) - Number(b))
            ),
        ];
    }

    async getSearchCriteria(
        unit: string,
        field: keyof PropertyFilterDetail,
        fieldValue: string
    ): Promise<string | undefined> {
        const details = await this.getFilterDetails();
        const searchCriteria = details.find(
            (item) =>
                item[field] === fieldValue &&
                item[PropertyFilter.UnitNumber] == unit
        );
        return searchCriteria?.unitFull;
    }

    getFileList(
        unitFull: string | null,
        serviceCharge: boolean = false
    ): Observable<string[]> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams()
            .append(PropertyFilter.Site, this.appConfig.selectedSite)
            .appendAll({
                [PropertyFilter.Site]: this.appConfig.selectedSite,
                ...(serviceCharge && {
                    serviceCharge: true,
                }),
                ...(unitFull && { [PropertyFilter.UnitFull]: unitFull }),
            });

        return this.http.get<string[]>(this.apiBaseUrl + '/filelist/', {
            headers,
            params,
        });
    }

    getPresignedUrl(key: string, operation: string): Observable<string> {
        let headers = new HttpHeaders()
            .set('X-Api-Key', this.apiKey)
            .set('Content-Type', 'application/pdf');
        let body = {
            key,
            operation,
            bucket: 'properties',
        };
        return this.http.post<string>(this.apiBaseUrl + '/presign/', body, {
            headers,
        });
    }

    getServiceCharge(key: string, operation: string): Observable<string> {
        let headers = new HttpHeaders()
            .set('X-Api-Key', this.apiKey)
            .set('Content-Type', 'application/pdf');
        let body = {
            key: `${this.appConfig.selectedSite}/${key}`,
            operation,
            bucket: 'serviceCharge',
        };
        return this.http.post<string>(this.apiBaseUrl + '/presign/', body, {
            headers,
        });
    }

    fileUpload(url: string, key: string, file: File, meta?: any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });
        if (meta)
            Object.keys(meta).forEach((key) =>
                headers.set(`x-amz-meta-${key}`, meta[key])
            );
        return this.http.put(url, file, {
            headers,
            reportProgress: true,
            observe: 'events',
        });
    }

    deleteFile(key: string) {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let body = { key, bucket: 'properties' };
        return this.http.post<string>(this.apiBaseUrl + '/deletefile', body, {
            headers,
        });
    }

    async getReportData(force?: boolean): Promise<PropertyDetail[]> {
        this.lastUpdated =
            localStorage.getItem(StorageKeys.ReportDataLastUpdate) || '';

        if (
            !this.reportData ||
            force ||
            this.lastUpdated === '' ||
            (this.lastUpdated &&
                differenceInMinutes(new Date(), new Date(this.lastUpdated)) > 5)
        ) {
            this.updating = true;
            let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
            let params = new HttpParams().append(
                'site',
                this.appConfig.selectedSite
            );

            try {
                this.reportData = await firstValueFrom(
                    this.http.get<PropertyDetail[]>(
                        this.apiBaseUrl + '/property/report/',
                        { headers, params }
                    )
                );
                this.lastUpdated = new Date().toISOString();
                localStorage.setItem(
                    StorageKeys.ReportDataLastUpdate,
                    this.lastUpdated
                );
            } catch {
                return this.reportData || [];
            }
        }
        this.updating = false;
        return this.reportData;
    }

    getPaymentList(unitFull: string): Observable<Transaction[]> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams()
            .append(PropertyFilter.UnitFull, unitFull)
            .append('site', this.appConfig.selectedSite);
        return this.http.get<Transaction[]>(
            this.apiBaseUrl + '/property/transaction',
            { headers, params }
        );
    }

    addTransaction(transactionData: Transaction, unitFull: string) {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.put(
            this.apiBaseUrl + '/property/transaction',
            {
                ...transactionData,
                unitFull,
                site: this.appConfig.selectedSite,
                id: Guid.create().toString(),
            },
            { headers: headers }
        );
    }

    removeTransaction(id: string, unitFull: string) {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.post(
            this.apiBaseUrl + '/property/transaction',
            { id, unitFull, site: this.appConfig.selectedSite },
            { headers: headers }
        );
    }

    updateServiceCharge(
        budgetYear: string,
        unitFull: string,
        budgetMonth: string
    ) {
        const headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        const params = new HttpParams()
            .append('budgetYear', budgetYear)
            .append('unitFull', unitFull)
            .append('budgetMonth', budgetMonth)
            .append('site', this.appConfig.selectedSite);
        return this.http.get(
            this.apiBaseUrl + '/property/updateservicecharge',
            {
                headers,
                params,
                responseType: 'text',
            }
        );
    }

    getStatement(unitFull: string, startDate: string, endDate: string) {
        let headers = new HttpHeaders()
            .set('X-Api-Key', this.apiKey)
            .set('Content-Type', 'application/pdf');
        let body = {
            unitFull,
            startDate,
            endDate,
            site: this.appConfig.selectedSite,
        };
        return this.http.post<string>(
            this.apiBaseUrl + '/property/statement',
            body,
            { headers }
        );
    }

    getAllPayments(
        budgetYear?: string
    ): Observable<(Transaction & { unitFull: string })[]> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        const params = new HttpParams().appendAll({
            site: this.appConfig.selectedSite,
            ...(budgetYear && { budgetYear }),
        });

        return this.http.get<(Transaction & { unitFull: string })[]>(
            this.apiBaseUrl + '/property/get-all-payments',
            {
                headers,
                params,
            }
        );
    }

    getAllTrans(
        budgetYear?: string,
        boilerCharges?: boolean
    ): Observable<(Transaction & { unitFull: string })[]> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        const params = new HttpParams().appendAll({
            site: this.appConfig.selectedSite,
            ...(budgetYear && { budgetYear }),
            ...(boilerCharges && { boilerCharges }),
        });
        return this.http.get<(Transaction & { unitFull: string })[]>(
            this.apiBaseUrl + '/property/get-all-trans',
            {
                headers,
                params,
            }
        );
    }

    updatePreferences(
        unitFull: string,
        site: string,
        shouldPost: boolean,
        id: string
    ) {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.put(
            this.apiBaseUrl + '/preferences/post',
            { unitFull, site, shouldPost, id },
            { headers }
        );
    }

    getStats() {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams().append(
            'site',
            this.appConfig.selectedSite
        );

        return this.http.get<StatsResponse>(this.apiBaseUrl + '/stats', {
            headers,
            params,
        });
    }
}
