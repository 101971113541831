<app-page-toolbar
    [appVersion]="appVersion"
    [apiVersion]="apiVersion"
    [environment]="environment"
    [pageTitle]="'Dashboard'"
>
</app-page-toolbar>

<div
    class="m-5 border rounded-lg border-slate-100 bg-slate-50 p-4 pt-0 max-w-7xl mx-auto w-full"
>
    <app-card-title [title]="'Contractors'"></app-card-title>
    <div class="flex flex-col items-center justify-center">
        <small
            *ngIf="
                contractorApprovals.length === 0 &&
                !loading &&
                !notification?.updating
            "
            >No Contractors awaiting approval</small
        >
        <small *ngIf="loading || notification?.updating"
            >Loading Contractors...</small
        >
        <ng-container
            *ngIf="
                contractorApprovals.length !== 0 &&
                !loading &&
                !notification?.updating
            "
        >
            <div
                *ngFor="let approval of contractorApprovals"
                [routerLink]="[
                    '/home-page/view-contractor',
                    approval.applicationId
                ]"
                class="bg-cw-blue text-gray-50 p-2 my-1 w-full flex flex-row justify-between items-center rounded-md hover:cursor-pointer hover:bg-cw-blue-light"
            >
                <p class="m-0 max-w-[30%] w-[30%] text-left">
                    Contractor Application
                </p>
                <p class="m-0 max-w-[30%] w-[30%] text-center">
                    Awaiting Approval
                </p>
                <p class="m-0 max-w-[30%] w-[30%] text-right">
                    {{ approval.companyName }}
                </p>
            </div>
        </ng-container>
    </div>
</div>

<div
    class="m-5 border rounded-lg border-slate-100 bg-slate-50 p-4 pt-0 max-w-7xl mx-auto w-full"
>
    <app-card-title [title]="'Authorisations'"></app-card-title>
    <div class="flex flex-col items-center justify-center">
        <small
            *ngIf="
                workOrderAuths.length === 0 &&
                !loading &&
                !notification?.updating
            "
            >Nothing awaiting authorisation</small
        >
        <small *ngIf="loading || notification?.updating"
            >Loading Work Orders...</small
        >

        <ng-container
            *ngIf="
                workOrderAuths.length !== 0 &&
                !loading &&
                !notification?.updating
            "
        >
            <div
                *ngFor="let approval of workOrderAuths"
                [routerLink]="[
                    '/home-page/view-work-order',
                    approval.orderNumber
                ]"
                class="bg-cw-blue text-gray-50 p-2 my-1 w-full flex flex-row justify-between items-center rounded-md hover:cursor-pointer hover:bg-cw-blue-light"
            >
                <p class="m-0 max-w-[30%] w-[30%] text-left">Work Order</p>
                <p class="m-0 max-w-[30%] w-[30%] text-center">
                    {{ approval.status }}
                </p>
                <p class="m-0 max-w-[30%] w-[30%] text-right">
                    #{{ approval.orderNumber }}
                </p>
            </div>
        </ng-container>
    </div>
</div>

<div
    class="m-5 border rounded-lg border-slate-100 bg-slate-50 p-4 pt-0 max-w-7xl mx-auto w-full"
>
    <app-card-title [title]="'Approvals'"></app-card-title>
    <div class="flex flex-col items-stretch justify-center">
        <small
            *ngIf="
                workOrderInvoiceApprovals.length === 0 &&
                !loading &&
                !notification?.updating
            "
            class="text-center"
            >Nothing awaiting approval</small
        >
        <small *ngIf="loading || notification?.updating" class="text-center"
            >Loading Work Orders...</small
        >

        <ng-container
            *ngIf="
                workOrderInvoiceApprovals.length !== 0 &&
                !loading &&
                !notification?.updating
            "
        >
            <div
                *ngFor="let approval of workOrderInvoiceApprovals"
                [routerLink]="[
                    '/home-page/view-work-order',
                    approval.orderNumber
                ]"
                class="bg-cw-blue text-gray-50 p-2 my-1 w-full flex flex-row justify-between items-center rounded-md hover:cursor-pointer hover:bg-cw-blue-light"
            >
                <p class="m-0 max-w-[30%] w-[30%] text-left">Work Order</p>
                <p class="m-0 max-w-[30%] w-[30%] text-center">
                    {{ approval.status }}
                </p>
                <p class="m-0 max-w-[30%] w-[30%] text-right">
                    #{{ approval.orderNumber }}
                </p>
            </div>
        </ng-container>
    </div>
</div>

<footer class="fixed w-auto bottom-0 right-0">
    <div class="m-4 flex flex-row justify-center items-center">
        <button
            (click)="notification.triggerApprovals()"
            [disabled]="notification.updating"
            id="refreshButton"
            class="w-10 h-10"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-8"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
            </svg>
        </button>
        <small slot="end"
            >Last Updated:
            {{
                notification.updating
                    ? 'Updating...'
                    : (lastUpdated | date: 'dd-MM-yyyy, h:mm:ss a')
            }}
        </small>
    </div>
</footer>
