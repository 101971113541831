import { Pipe, PipeTransform } from '@angular/core';
import { AuditTypes } from '../../services/audit/audit.service';

@Pipe({
    name: 'propAudits',
})
export class PropAuditsPipe implements PipeTransform {
    transform(value: AuditTypes, ...args: unknown[]): string {
        switch (value) {
            case AuditTypes.AddTransaction:
                return 'added transaction';
            case AuditTypes.deleteTransaction:
                return 'deleted transaction';
            case AuditTypes.Note:
                return 'added comment';
            case AuditTypes.UpdateDetails:
                return 'updated details';
            case AuditTypes.UploadFile:
                return 'uploaded file';
            case AuditTypes.DeleteFile:
                return 'deleted file';
            case AuditTypes.UpdateServiceCharge:
                return 'updated service Charge';
            case AuditTypes.TransferOwner:
                return 'transferred owner';
            case AuditTypes.CreateEntry:
                return 'created entry';
            case AuditTypes.UpdatedPost:
                return 'updated correspondence preferences';
        }
    }
}
