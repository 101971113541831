import { Injectable } from '@angular/core';
import { Audit } from '../../interfaces/property';
import { AuthServiceService } from '../auth-service/auth-service.service';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { AppConfigService } from '../app-config/app-config.service';
import { firstValueFrom } from 'rxjs';

export enum AuditTypes {
    UpdateDetails = 'Update Details',
    Note = 'Note',
    AddTransaction = 'Add Transaction',
    deleteTransaction = 'Delete Transaction',
    UploadFile = 'Upload File',
    DeleteFile = 'Delete File',
    UpdateServiceCharge = 'Updated Service Charge',
    TransferOwner = 'Transfer Owner',
    CreateEntry = 'Create Entry',
    UpdatedPost = 'Updated correspondence preferences',
}

@Injectable({
    providedIn: 'root',
})
export class AuditService {
    apiKey = environment.apiKey;
    apiBaseUrl = environment.apiBaseUrl;

    constructor(
        public auth: AuthServiceService,
        public http: HttpClient,
        public logger: NGXLogger,
        public appConfig: AppConfigService
    ) {}

    createAudit = async (
        type: AuditTypes,
        message: string,
        unitFull: string
    ): Promise<string | void> => {
        const date = new Date();

        const body: Audit = {
            type,
            message,
            user: (await this.auth.getUserName()) || 'Unknown User',
            date: date.toISOString(),
            ['unitFull#timestamp']: `${unitFull}#${date.getTime()}`,
            site: this.appConfig.selectedSite,
        };

        try {
            let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
            return await firstValueFrom(
                this.http.put<string>(
                    this.apiBaseUrl + '/property/audit',
                    body,
                    { headers }
                )
            );
        } catch (error: any) {
            console.error(error);
        }
    };

    getAudits = async (unitFull: string) => {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        const params = new HttpParams()
            .set('unitFull', unitFull)
            .set('site', this.appConfig.selectedSite);

        return await firstValueFrom(
            this.http.get<Audit[]>(this.apiBaseUrl + '/property/audit', {
                headers,
                params,
            })
        );
    };

    deleteAudit = async (unitFull: string, timestamp: string) => {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        const params = new HttpParams()
            .set('unitFull', unitFull)
            .set('site', this.appConfig.selectedSite)
            .set('timestamp', timestamp);

        return await firstValueFrom(
            this.http.delete<HttpResponse<any>>(
                this.apiBaseUrl + '/property/audit',
                {
                    headers,
                    observe: 'response',
                    params,
                }
            )
        );
    };

    createWorkOrderAudit = async (event: string, date?: Date) => {
        return {
            event,
            user: (await this.auth.getUserName()) || 'Unknown User',
            date: (date ?? new Date()).toISOString(),
        };
    };
}
