import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ContractorDetails,
    SignUpForm,
} from '../contractor-sign-up/contractor-sign-up.component';
import { WorkOrder } from '../work-orders/work-orders.component';
import { ContractorsService } from '../../services/contractors/contractors.service';
import { WorkOrdersService } from '../../services/work-orders/work-orders.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmModalComponent } from '../../components/modals/confirm-modal/confirm-modal.component';
import { ContractorFileUploadComponent } from '../../components/contractor-file-upload/contractor-file-upload.component';
import { InfoModalComponent } from '../../components/modals/info-modal/info-modal.component';
import { ContractorWorkOrderComponent } from '../../components/contractor-work-order/contractor-work-order.component';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import { WorkOrderStatus } from '../../enums/work-orders';
import { BreakpointObserver } from '@angular/cdk/layout';
import { isToday } from 'date-fns';
import * as Sentry from '@sentry/angular-ivy';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';

@Component({
    selector: 'app-contractor-dashboard',
    templateUrl: './contractor-dashboard.component.html',
    styleUrls: ['./contractor-dashboard.component.css'],
})
export class ContractorDashboardComponent implements OnInit {
    applicationId!: string;
    loading: boolean = true;
    contractor!: ContractorDetails;
    workOrders!: Partial<WorkOrder>[];
    openWorkOrders!: Partial<WorkOrder>[];
    closedWorkOrders!: Partial<WorkOrder>[];
    files!: { fileKey: string; metaData: any }[];
    mappedFiles!: { fileKey: string; expiry?: Date; updatingExpiry: boolean }[];
    opened!: boolean;
    mobile: boolean = true;

    contractorForm!: FormGroup;
    messageText: string = '';
    messageBanner: boolean = false;

    constructor(
        public route: ActivatedRoute,
        public contractorService: ContractorsService,
        public workOrderService: WorkOrdersService,
        public dialog: MatDialog,
        public auth: AuthServiceService,
        public router: Router,
        public observer: BreakpointObserver,
        public config: AppConfigService
    ) {}

    async ngOnInit(): Promise<void | boolean> {
        this.loading = true;

        let isAuth = this.auth.isLoggedIn();
        if (!isAuth) return this.router.navigate(['']);

        const lastRefresh = localStorage.getItem('lastRefresh');
        if (!lastRefresh || !isToday(new Date(lastRefresh))) {
            const dateNow = new Date();
            localStorage.setItem('lastRefresh', dateNow.toDateString());
            location.reload();
        }

        this.applicationId = (await firstValueFrom(this.route.params))['id'];
        if (this.applicationId !== (await this.auth.getProfile()))
            return this.logOut();

        const [userName, email] = await this.auth.getUser();
        Sentry.setUser({
            username: userName,
            email,
        });

        this.contractor = await firstValueFrom(
            this.contractorService.getContractorDetails(this.applicationId)
        );
        await this.getWorkOrders();

        this.loading = false;
    }

    /**
     * Angular lifecycle event
     */
    async ngAfterViewInit(): Promise<void> {
        this.observer.observe(['(max-width: 1000px)']).subscribe((res) => {
            this.opened = !res.matches;
            this.mobile = res.matches;
        });
    }

    async getWorkOrders() {
        await this.config.getConfigs();

        this.workOrders = (
            await Promise.all(
                this.config.sites.map(async (site) => {
                    console.log(site);
                    return await firstValueFrom(
                        this.workOrderService.getContractorOrders(
                            this.applicationId,
                            site
                        )
                    );
                })
            )
        )
            .flat()
            .sort((a, b) => b!.orderNumber!.localeCompare(a!.orderNumber!));

        this.openWorkOrders = this.workOrders
            .filter((order) =>
                [
                    WorkOrderStatus.AwaitingPayment,
                    WorkOrderStatus.Open,
                    WorkOrderStatus.InvoiceApproval,
                ].includes(order.status as WorkOrderStatus)
            )
            .sort((a, b) => b!.orderNumber!.localeCompare(a!.orderNumber!));

        this.closedWorkOrders = this.workOrders
            .filter((order) =>
                [WorkOrderStatus.Closed, WorkOrderStatus.Paid].includes(
                    order.status as WorkOrderStatus
                )
            )
            .sort((a, b) => b!.orderNumber!.localeCompare(a!.orderNumber!));

        this.files = await firstValueFrom(
            await this.contractorService.getFileList(this.applicationId)
        );

        this.mappedFiles = this.files.map((file) => ({
            fileKey: file.fileKey,
            expiry: file?.metaData?.Metadata?.expiry
                ? new Date(+file?.metaData?.Metadata?.expiry)
                : undefined,
            updatingExpiry: false,
        }));
        this.initialiseForm();
    }

    initialiseForm() {
        this.contractorForm = new FormGroup<Partial<SignUpForm>>({
            applicationId: new FormControl(
                { value: this.contractor.applicationId, disabled: true },
                Validators.required
            ),
            companyName: new FormControl(
                { value: this.contractor?.companyName, disabled: true },
                Validators.required
            ),
            contactName: new FormControl(
                { value: this.contractor?.contactName, disabled: false },
                Validators.required
            ),
            email: new FormControl(
                { value: this.contractor?.email, disabled: true },
                [Validators.email, Validators.required]
            ),
            phone: new FormControl(
                {
                    value: this.contractor?.phone,
                    disabled: false,
                },
                [Validators.required, Validators.pattern(/^[0-9]*$/)]
            ),
            addressOne: new FormControl(
                { value: this.contractor?.addressOne, disabled: false },
                Validators.required
            ),
            addressTwo: new FormControl({
                value: this.contractor?.addressTwo,
                disabled: false,
            }),
            addressThree: new FormControl({
                value: this.contractor?.addressThree,
                disabled: false,
            }),
            postcode: new FormControl(
                { value: this.contractor?.postcode, disabled: false },
                Validators.required
            ),
            consent: new FormControl(
                { value: this.contractor?.consent, disabled: false },
                Validators.requiredTrue
            ),
            bankName: new FormControl({
                value: this.contractor?.bankName,
                disabled: false,
            }),
            sortCode: new FormControl({
                value: this.contractor?.sortCode,
                disabled: false,
            }),
            accountNumber: new FormControl({
                value: this.contractor?.accountNumber,
                disabled: false,
            }),
            accountName: new FormControl({
                value: this.contractor?.accountName,
                disabled: false,
            }),
            workOrderEmails: new FormControl({
                value: this.contractor?.workOrderEmails ?? true,
                disabled: false,
            }),
        });
    }

    /**
     * * View file
     * @param fileName
     */
    openFile(fileName: string): void {
        const windowRef = window.open();
        firstValueFrom(
            this.contractorService.getPresignedUrl(fileName, 'Get')
        ).then((url) => {
            if (windowRef) windowRef.location = url;
        });
    }

    /**
     * Delete file for property
     * @param file
     */
    deleteFile(file: string): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: { message: 'Are you sure you want to delete this file?' },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.contractorService.deleteFile(file).subscribe(async () => {
                    this.files = await firstValueFrom(
                        await this.contractorService.getFileList(
                            this.applicationId
                        )
                    );

                    this.mappedFiles = this.files.map((file) => ({
                        fileKey: file.fileKey,
                        expiry: file?.metaData?.Metadata?.expiry
                            ? new Date(+file?.metaData?.Metadata?.expiry)
                            : undefined,
                        updatingExpiry: false,
                    }));
                });
            }
        });
    }

    /**
     * Open file upload modal
     */
    openFileUploadModal = (): void => {
        const fileRef = this.dialog.open(ContractorFileUploadComponent, {
            width: '500px',
            data: {
                applicationId: this.applicationId,
            },
            disableClose: true,
        });

        fileRef.afterClosed().subscribe(async () => {
            this.files = await firstValueFrom(
                await this.contractorService.getFileList(this.applicationId)
            );
            this.mappedFiles = this.files.map((file) => ({
                fileKey: file.fileKey,
                expiry: file?.metaData?.Metadata?.expiry
                    ? new Date(+file?.metaData?.Metadata?.expiry)
                    : undefined,
                updatingExpiry: false,
            }));
        });
    };

    openWorkOrderModal = (workOrder: Partial<WorkOrder>) => {
        const dialog = this.dialog.open(ContractorWorkOrderComponent, {
            width: '1000px',
            maxWidth: '90vw !important',
            height: '900px',
            data: { workOrder },
        });

        dialog.afterClosed().subscribe(async () => {
            await this.getWorkOrders();
        });
    };

    /**
     * Log user out of App and return to landing page
     */
    logOut = async (): Promise<void> => {
        await this.auth.signOut();
    };

    resetForm = (): void => {
        this.initialiseForm();
        this.contractorForm.markAsPristine();
    };

    async canDeactivate() {
        return new Promise((resolve) => {
            if (this.contractorForm && this.contractorForm.dirty) {
                const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    width: '500px',
                    data: {
                        message:
                            'You have unsaved changes that will be lost, are you sure?',
                    },
                });

                dialogRef.afterClosed().subscribe(async (result) => {
                    if (!result) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    /**
     * Show Message banner
     *
     * @param text
     */
    showMessageBanner = (text: string): void => {
        this.messageText = text;
        this.messageBanner = true;
        setTimeout(() => (this.messageBanner = false), 3000);
    };

    async saveChanges(): Promise<void> {
        const changedValues: Partial<ContractorDetails> = {
            applicationId: this.applicationId,
        };

        Object.keys(this.contractorForm.controls).forEach((controlName) => {
            if (this.contractorForm.get(controlName)?.dirty) {
                changedValues[controlName as keyof ContractorDetails] =
                    this.contractorForm.get(controlName)?.value;
            }
        });

        if (Object.keys(changedValues).length > 1) {
            try {
                const submitResponse = await firstValueFrom(
                    this.contractorService.updateContractor(changedValues)
                );
                this.contractorForm.markAsPristine();
                this.showMessageBanner(submitResponse.body);
            } catch {
                this.openInfoDialog(
                    'Cannot connect to Server. Changes have not been saved.'
                );
            }
        }
    }

    /**
     * Open info dialog
     * @param message
     */
    openInfoDialog = (message: string): void => {
        this.dialog.open(InfoModalComponent, {
            width: '500px',
            data: { message },
        });
    };
}
