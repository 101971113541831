<div class="flex flex-col items-center justify-center h-full">
    <img class="h-[250px]" alt="" id="signUpLogo" src="assets/navLogo.png" />
    <div
        class="rounded-lg bg-slate-300 h-[200px] w-[500px] p-4 flex flex-col justify-center items-center"
    >
        <h3 class="font-poppins text-lg text-center" *ngIf="updating">
            Updating...
        </h3>
        <h3
            class="font-poppins text-lg text-center"
            *ngIf="!invalidParams && !updating && !error"
        >
            Updated Preferences for {{ unitFull }}: <br />{{
                shouldPost === 'true' ? 'Email & Post' : 'Email only'
            }}
        </h3>
        <p
            class="font-poppins text-md text-center"
            *ngIf="!invalidParams && !updating && !error"
        >
            Thank you for updating.
        </p>
        <h3
            class="font-poppins text-lg text-center"
            *ngIf="invalidParams || error"
        >
            An error occurred while updating your preferences.
        </h3>
    </div>
</div>
