import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { format, isSameMonth, parseISO } from 'date-fns';
import { ChartOptions } from '../variance-chart/variance-chart.component';
import { ChartComponent } from 'ng-apexcharts';
import { Transaction } from '../../interfaces/property';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';

@Component({
    selector: 'app-income-chart',
    templateUrl: './income-chart.component.html',
    styleUrls: ['./income-chart.component.css'],
})
export class IncomeChartComponent implements OnInit, OnChanges {
    @ViewChild('chart') chart!: ChartComponent;
    @Input() budgetYear: string = '';
    @Input() allUnits!: Transaction[];
    @Input() totalExp!: number;
    budgetStartYear: number = 0;
    chartOptions!: ChartOptions;

    constructor(public configService: AppConfigService) {}

    ngOnInit(): void {}

    ngOnChanges() {
        this.budgetStartYear = +this.budgetYear.split('-')[0];

        this.chartOptions = {
            colors: ['#6e97c9', '#FFA500', '#c5c6c8'],
            fill: { type: 'solid', opacity: 1 },
            stroke: {
                show: true,
                lineCap: 'round',
                width: [5, 0, 0],
            },
            series: [
                {
                    name: 'Cumulative Income',
                    data: this.calculateCumulativeIncomeData(),
                    type: 'line',
                },
                {
                    name: 'Service Charge Income',
                    data: this.calculateMonthlyIncome(),
                    type: 'bar',
                },
                {
                    name: 'Other Income',
                    data: this.calculateOtherIncome(),
                    type: 'bar',
                },
            ],
            chart: {
                height: 300,
                type: 'line',
                fontFamily: 'Poppins, sans-serif',
                redrawOnParentResize: true,
                stacked: true,
            },
            dataLabels: {
                enabled: false,
            },

            title: {
                text: 'Income',
            },
            xaxis: {
                categories: this.arrayOfMonths().map((date) =>
                    format(date, 'MMM')
                ),
            },
            yaxis: {
                title: { text: 'Amount ( £ )' },
                labels: {
                    formatter: (value) => {
                        return `£ ${this.numberWithCommas(Math.round(value))}`;
                    },
                },
            },
        };
    }

    arrayOfMonths = () => {
        const configData = this.configService?.config?.budgetData;
        const startingDate: number = new Date(
            configData?.startDate ?? 0
        ).getMonth();

        return Array.from({ length: 12 }, (_, month) =>
            parseISO(
                new Date(
                    this.budgetStartYear,
                    month + startingDate,
                    10
                ).toISOString()
            )
        );
    };

    numberWithCommas(x: number) {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }

    calculateCumulativeIncomeData = () => {
        const onlyPayments = this.allUnits.filter(
            (trans: Transaction | undefined) =>
                !!trans &&
                ['Payment', 'Refund Payment'].includes(trans.transactionType)
        );

        return this.arrayOfMonths().map((month, index, array) => {
            const payments = onlyPayments.filter((transaction) => {
                return array
                    .slice(0, index + 1)
                    .some((prevMonth) =>
                        isSameMonth(prevMonth, new Date(transaction?.date!))
                    );
            });

            return +payments
                .reduce(
                    (total, trans) =>
                        trans?.transactionType === 'Payment'
                            ? total + trans?.amount
                            : total - (trans?.amount ?? 0),
                    0
                )
                .toFixed(2);
        });
    };

    calculateMonthlyIncome = () => {
        const onlyPayments = this.allUnits
            .filter(
                (trans: Transaction | undefined) =>
                    !!trans &&
                    ['Payment', 'Refund Payment'].includes(
                        trans.transactionType
                    )
            )
            .filter((trans) => trans['unitFull#id'].split('#')[0] !== 'estate');

        return this.arrayOfMonths().map((month, index, array) => {
            const payments = onlyPayments.filter((transaction) => {
                return isSameMonth(month, new Date(transaction?.date!));
            });

            return +payments
                .reduce(
                    (total, trans) =>
                        trans?.transactionType === 'Payment'
                            ? total + trans?.amount
                            : total - (trans?.amount ?? 0),
                    0
                )
                .toFixed(2);
        });
    };

    calculateOtherIncome = () => {
        const onlyPayments = this.allUnits
            .filter(
                (trans: Transaction | undefined) =>
                    !!trans &&
                    ['Payment', 'Refund Payment'].includes(
                        trans.transactionType
                    )
            )
            .filter((trans) => trans['unitFull#id'].split('#')[0] === 'estate');

        return this.arrayOfMonths().map((month, index, array) => {
            const payments = onlyPayments.filter((transaction) => {
                return isSameMonth(month, new Date(transaction?.date!));
            });

            return +payments
                .reduce(
                    (total, trans) =>
                        trans?.transactionType === 'Payment'
                            ? total + trans?.amount
                            : total - (trans?.amount ?? 0),
                    0
                )
                .toFixed(2);
        });
    };
}
