<app-page-toolbar [pageTitle]="'Work Orders'"></app-page-toolbar>

<div
    class="mx-auto flex w-full max-w-7xl mt-5 mobile:mt-0 tablet:mt-5 desktop:mt-5 items-start mobile:flex-col tablet:flex-row desktop:flex-row"
>
    <div
        class="flex-1 p-0 mobile:w-full mt-0 divide-y divide-gray-200 overflow-visible rounded-lg bg-slate-50 shadow mb-5 mx-5 tablet:mx-5 mobile:mx-0 desktop:mx-5"
    >
        <div class="m-4 p-2">
            <details class="status">
                <summary class="cursor-pointer">
                    <div class="flex flex-row items-center justify-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-6"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                            />
                        </svg>

                        <h3 class="m-0 ml-2 font-poppins text-sm">
                            Work Order Statuses
                        </h3>
                    </div>
                </summary>

                <h3 class="m-0 mt-2 text-sm font-poppins">Active Statuses</h3>
                <hr class="bg-gray-300 h-px border-0 mb-2" />
                <dl>
                    <div class="flex flex-col pb-3">
                        <dt class="font-bold text-xs">
                            {{ WorkOrderStatus.Open }}
                        </dt>
                        <dd class="text-xs">
                            Work Order open and ready for work to begin.
                        </dd>
                    </div>
                    <div class="flex flex-col pb-3">
                        <dt class="font-bold text-xs">
                            {{ WorkOrderStatus.AwaitingAuth }}
                        </dt>
                        <dd class="text-xs">
                            Work Order has been created by someone without high
                            enough authorisation limit, and is awaiting approval
                            from someone with appropriate limit.
                        </dd>
                    </div>
                    <div class="flex flex-col pb-3">
                        <dt class="font-bold text-xs">
                            {{ WorkOrderStatus.InvoiceApproval }}
                        </dt>
                        <dd class="text-xs">
                            Invoice has been uploaded, and is awaiting approval.
                        </dd>
                    </div>
                    <div class="flex flex-col pb-3">
                        <dt class="font-bold text-xs">
                            {{ WorkOrderStatus.AwaitingPayment }}
                        </dt>
                        <dd class="text-xs">
                            Invoice has been approved, but not yet paid.
                        </dd>
                    </div>
                </dl>

                <h3 class="m-0 mt-2 text-sm font-poppins">Closed Statuses</h3>
                <hr class="bg-gray-300 h-px border-0 mb-2" />

                <dl>
                    <div class="flex flex-col pb-3">
                        <dt class="font-bold text-xs">
                            {{ WorkOrderStatus.Paid }}
                        </dt>
                        <dd class="text-xs">Invoice has been paid.</dd>
                    </div>
                    <div class="flex flex-col pb-3">
                        <dt class="font-bold text-xs">Deleted</dt>
                        <dd class="text-xs">
                            Work Order is not longer relevant / required.
                        </dd>
                    </div>
                </dl>
            </details>
        </div>
        <div class="inline-block py-2 min-w-full align-middle">
            <table class="min-w-full divide-y-2 divide-gray-300 table-fixed">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                        >
                            Order No.
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                            Date Created
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                            Status
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                            Contractor
                        </th>
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                            Total
                        </th>
                    </tr>
                </thead>

                <tbody
                    class="divide-y-2 divide-gray-200 bg-white"
                    *ngIf="!filtering"
                >
                    <tr
                        *ngFor="let workOrder of filteredOrders"
                        [routerLink]="[
                            '/home-page/view-work-order',
                            workOrder.orderNumber
                        ]"
                        class="hover:bg-gray-200 hover:cursor-pointer group"
                    >
                        <td
                            class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-500"
                        >
                            #{{ workOrder.orderNumber }}
                        </td>
                        <td
                            class="whitespace-nowrap px-3 py-4 text-xs text-gray-500"
                        >
                            {{ workOrder.dateCreated | date: 'dd-MM-yyyy' }}
                        </td>
                        <td
                            class="whitespace-nowrap px-3 py-4 text-xs text-gray-500"
                        >
                            {{
                                workOrder.status === WorkOrderStatus.Closed
                                    ? 'Deleted'
                                    : workOrder.status
                            }}
                        </td>
                        <td
                            class="whitespace-nowrap px-3 py-4 text-xs text-gray-500"
                        >
                            <div
                                class="w-[150px] overflow-hidden overflow-ellipsis"
                            >
                                {{ workOrder.contractorId.companyName }}
                            </div>
                        </td>
                        <td
                            class="whitespace-nowrap px-3 py-4 text-xs text-gray-500"
                        >
                            £ {{ getTotal(workOrder) }}
                        </td>
                        <!-- Component Start -->

                        <div
                            class="absolute flex-col items-center hidden desktop:group-hover:flex"
                        >
                            <span
                                class="relative z-10 p-4 rounded-r-md border-solid border-gray-500 text-xs font-poppins leading-none whitespace-no-wrap bg-gray-200 shadow-lg w-[300px]"
                                ><h3 class="font-poppins text-sm">
                                    Description
                                </h3>
                                {{ workOrder.description }}
                            </span>
                        </div>

                        <!-- Component End  -->
                    </tr>
                </tbody>
            </table>
            <div
                class="w-full h-20 flex justify-center items-center font-poppins"
                *ngIf="filtering"
            >
                <h3 class="align-middle">Filtering Work Orders...</h3>
            </div>
        </div>
    </div>

    <aside
        class="sticky flex-col shrink-0 mr-5 ml-0 w-full tablet:w-96 order-first tablet:order-last top-[20px] tablet:top-[155px] mb-5"
    >
        <div
            *ngIf="filterForm"
            class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 mx-5 tablet:mx-0 mt-5 tablet:mt-0"
            [formGroup]="filterForm"
        >
            <dl class="flex flex-wrap">
                <div class="flex-auto px-6 pt-6">
                    <app-cw-primary-button
                        [routerLink]="'/home-page/create-works-order'"
                        [showButton]="authLimit && authLimit > 0"
                        [buttonText]="'Create New Work Order'"
                    >
                    </app-cw-primary-button>
                </div>

                <details
                    [open]="true"
                    class="my-6 w-full flex-col flex-none gap-y-2 border-t border-gray-900/5 px-6 pt-6 flex"
                >
                    <summary class="text-sm font-poppins m-0 cursor-pointer">
                        Filter Options
                        <hr />
                    </summary>

                    <fieldset class="mt-1">
                        <legend class="font-poppins font-semibold mb-1">
                            Contractor
                        </legend>

                        <select
                            id="contractor"
                            name="contractor"
                            formControlName="contractor"
                            class="block font-poppins h-8 bg-slate-50 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cw-blue sm:text-sm sm:leading-6"
                        >
                            <option value="">-</option>
                            <option
                                *ngFor="let option of contractors"
                                [value]="option"
                            >
                                {{ startCase(option) }}
                            </option>
                        </select>
                    </fieldset>

                    <fieldset class="mt-1">
                        <legend class="font-poppins font-semibold mb-1">
                            Expense Category
                        </legend>

                        <select
                            id="expense"
                            name="expense"
                            formControlName="expenseCategory"
                            class="block font-poppins h-8 bg-slate-50 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cw-blue sm:text-sm sm:leading-6"
                        >
                            <option value="">-</option>
                            <option
                                *ngFor="let option of expenseCats"
                                [value]="option[0]"
                            >
                                {{ startCase(option[1]) }}
                            </option>
                        </select>
                    </fieldset>

                    <fieldset formArrayName="status" class="mt-1">
                        <legend class="font-poppins font-semibold mb-1">
                            Status
                        </legend>
                        <div
                            *ngFor="let status of statusOptions; index as i"
                            class="flex flex-row justify-start align-text-top gap-x-2 w-full mt-1"
                        >
                            <input
                                type="checkbox"
                                id="{{ status }}"
                                class="align-middle rounded checked:bg-cw-blue checked:border-transparent"
                                formControlName="{{ i }}"
                            />
                            <label class="text-xs" for="{{ status }}">{{
                                status === 'Closed' ? 'Deleted' : status
                            }}</label>
                        </div>
                    </fieldset>

                    <fieldset formArrayName="yearCreated" class="mt-1">
                        <legend class="font-poppins font-semibold mb-1">
                            Year Created
                        </legend>
                        <div
                            *ngFor="let year of createdYears; index as i"
                            class="flex flex-row justify-start align-text-top gap-x-2 w-full mt-1"
                        >
                            <input
                                type="checkbox"
                                id="{{ year }}"
                                class="align-middle rounded checked:bg-cw-blue checked:border-transparent"
                                formControlName="{{ i }}"
                            />
                            <label class="text-xs" for="{{ year }}">{{
                                year
                            }}</label>
                        </div>
                    </fieldset>
                </details>
            </dl>
            <div class="ring-1 ring-gray-900/5 py-2">
                <h3 class="text-center font-poppins text-sm my-0">
                    Results: {{ filteredOrders.length }}
                </h3>
            </div>
        </div>
    </aside>
</div>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>
