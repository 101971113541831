<app-page-toolbar [backButtonRoute]="'/home-page/work-orders'">
</app-page-toolbar>

<mat-card *ngIf="workOrderForm" [formGroup]="workOrderForm">
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">add</mat-icon>
            {{
                isRetro
                    ? 'Add Retrospective Work Order'
                    : 'Create new Work Order'
            }}
        </mat-card-title>
        <mat-slide-toggle
            *ngIf="userGroups.includes(UserGroups.PaymentAdmin)"
            [(ngModel)]="isRetro"
            (ngModelChange)="toggleRetro()"
            [ngModelOptions]="{ standalone: true }"
        >
            Retrospective Work Order
        </mat-slide-toggle>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field
            *ngIf="isRetro"
            appearance="outline"
            (click)="startPicker.open()"
        >
            <mat-label>Paid Date</mat-label>

            <input
                [matDatepicker]="startPicker"
                [max]="currentDate"
                [min]="minPaidDate"
                formControlName="paidDate"
                matInput
                placeholder="DD/MM/YYYY"
                readonly
            />
            <mat-datepicker-toggle
                [for]="startPicker"
                matSuffix
            ></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field
            *ngIf="isRetro"
            appearance="outline"
            (click)="fileInput.openFilePicker()"
        >
            <mat-label>Invoice</mat-label>
            <ngx-mat-file-input
                #fileInput
                [(ngModel)]="invoice"
                [ngModelOptions]="{ standalone: true }"
                accept=".pdf"
                [required]="true"
            >
            </ngx-mat-file-input>
        </mat-form-field>
        <small *ngIf="!!invoice && !isCorrectType(['application/pdf'], invoice)"
            >Unsupported file type. Please upload PDF</small
        >

        <mat-form-field appearance="outline">
            <mat-label
                >{{ loading ? 'Loading Contractors...' : 'Select Contractor' }}
            </mat-label>
            <mat-select
                formControlName="contractorId"
                [compareWith]="contractorCompare"
            >
                <mat-option
                    *ngFor="let contractor of approvedContractors"
                    [value]="contractor"
                    >{{ contractor?.companyName }}
                    {{ contractor.expired ? '(Expired Documents)' : '' }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label
                >{{
                    loading
                        ? 'Loading Expense Categories...'
                        : 'Select Expense Category'
                }}
            </mat-label>
            <mat-select formControlName="expenseCategory">
                <ng-container *ngFor="let expense of expenseCategory">
                    <ng-container
                        *ngIf="
                            expense.subCats &&
                            appConfig.selectedSite === 'century-wharf'
                        "
                        ><mat-option [value]="expense.code">
                            <b
                                >**DO NOT USE**
                                <strike>{{ expense.name }}</strike> **DO NOT
                                USE**</b
                            ></mat-option
                        >
                    </ng-container>
                    <ng-container *ngIf="!expense.subCats">
                        <mat-option [value]="expense.code">{{
                            expense.name
                        }}</mat-option>
                    </ng-container>

                    <ng-container *ngIf="expense.subCats">
                        <mat-option
                            *ngFor="let subCat of expense.subCats"
                            [value]="subCat.code"
                        >
                            {{ subCat.name }}
                        </mat-option>
                    </ng-container>
                </ng-container>

                <mat-option [value]="'RES'">RESERVES</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label
                >{{
                    loading ? 'Loading Lease Groups...' : 'Select Lease Groups'
                }}
            </mat-label>
            <mat-select
                [(ngModel)]="selectedLeaseGroups"
                (ngModelChange)="onSelectedLeaseGroupChange()"
                [multiple]="true"
                [ngModelOptions]="{ standalone: true }"
            >
                <mat-option
                    *ngFor="let group of leaseGroups"
                    [value]="group.code"
                    >{{ group.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div
            *ngIf="selectedLeaseGroups.length > 0"
            [formGroup]="getLeaseGroupsForms()"
            class="listDiv"
        >
            <mat-list dense>
                <mat-list-item
                    *ngFor="
                        let group of getLeaseGroupsForms().controls | keyvalue
                    "
                >
                    <div class="inputDiv">
                        <mat-label>{{ scheduleDisplay(group.key) }}</mat-label>
                        <div class="moneyDiv">
                            <input
                                [options]="{ prefix: '£ ' }"
                                autocomplete="off"
                                currencyMask
                                formControlName="{{ group.key }}"
                                matInput
                                placeholder="0.00"
                            />
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                    <div class="inputDiv">
                        <mat-label class="mat-body-strong">Total</mat-label>
                        <div class="moneyDiv">
                            <input
                                [(ngModel)]="totalValue"
                                [ngModelOptions]="{ standalone: true }"
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: true
                                }"
                                class="mat-body-strong"
                                currencyMask
                                matInput
                                readonly
                            />
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
        <small *ngIf="+totalValue > authLimit"
            >Authorisation Limit: £{{ authLimit }}.
            {{
                isRetro
                    ? 'Total Amount is higher than your Authorisation Limit.'
                    : 'Total Amount is higher than your' +
                      ' Authorisation Limit. This Work Order will be sent for Approval once created.'
            }}</small
        >

        <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
                formControlName="description"
                matInput
                rows="8"
            ></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Risks</mat-label>
            <mat-select [multiple]="true" formControlName="risks">
                <mat-option *ngFor="let risk of risks" [value]="risk"
                    >{{ risk }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button
            (click)="isRetro ? createRetroWorkOrder() : createWorkOrder()"
            [disabled]="
                workOrderForm.invalid ||
                submitting ||
                (isRetro &&
                    ((!!invoice &&
                        !isCorrectType(['application/pdf'], invoice)) ||
                        !invoice)) ||
                (isRetro && +totalValue > authLimit)
            "
            id="createButton"
            mat-raised-button
        >
            {{
                submitting
                    ? isRetro
                        ? 'Creating Work Order... Uploading Invoice...'
                        : 'Creating Work Order...'
                    : isRetro
                      ? 'Submit Retro Work Order'
                      : 'Create'
            }}
        </button>
        <br />
        <mat-progress-bar
            *ngIf="submitting"
            mode="indeterminate"
        ></mat-progress-bar>
    </mat-card-content>
</mat-card>
