import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Audit } from '../../../interfaces/property';

@Component({
    selector: 'app-audit-modal',
    templateUrl: './audit-modal.component.html',
    styleUrls: ['./audit-modal.component.css'],
})
export class AuditModalComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: { audits: Audit[] }) {}

    ngOnInit(): void {}
}
