import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { firstValueFrom } from 'rxjs';
import { ChartOptions } from 'src/app/components/variance-chart/variance-chart.component';
import { PropertyDetailsService } from 'src/app/services/property-details/property-details.service';

export type StatsResponse = {
    correspondence: { postal: number; email: number; total: number };
};

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.css'],
})
export class StatsComponent implements OnInit {
    @ViewChild('correspondenceChart') correspondenceChart!: ChartComponent;
    public correspondenceChartOptions!: Partial<ChartOptions>;
    stats!: StatsResponse;

    constructor(public propertyService: PropertyDetailsService) {}

    async ngOnInit(): Promise<void> {
        this.stats = await firstValueFrom(this.propertyService.getStats());
        this.correspondenceChartOptions = {
            colors: ['#6e97c9', '#FFA500'],
            series: [
                this.stats.correspondence.postal,
                this.stats.correspondence.email,
            ],
            labels: ['Postal', 'Email'],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true,
                            },
                            value: {
                                show: true,
                            },
                            total: {
                                show: true,
                            },
                        },
                    },
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex];
                },
            },
            chart: {
                type: 'donut',
                fontFamily: 'Poppins, sans-serif',
                redrawOnParentResize: true,
            },
        };
    }
}
