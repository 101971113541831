import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { PropertyDetailsService } from 'src/app/services/property-details/property-details.service';

@Component({
    selector: 'app-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.css'],
})
export class PreferencesComponent {
    unitFull?: string;
    site?: string;
    id?: string;
    shouldPost?: string;
    invalidParams: boolean = false;
    updating: boolean = true;
    error: boolean = false;

    constructor(
        public route: ActivatedRoute,
        public propertyService: PropertyDetailsService
    ) {
        this.route.queryParams.subscribe(async (params) => {
            this.updating = true;
            const { unitFull, site, id, shouldPost } = params;
            console.log(params);

            if (!unitFull || !site || !id || shouldPost === undefined) {
                this.updating = false;
                this.invalidParams = true;
                return;
            }

            if (shouldPost !== 'true' && shouldPost !== 'false') {
                this.updating = false;
                this.invalidParams = true;
                return;
            }

            Object.assign(this, { unitFull, site, id, shouldPost });

            try {
                await firstValueFrom(
                    this.propertyService.updatePreferences(
                        unitFull,
                        site,
                        shouldPost,
                        id
                    )
                );
            } catch (error) {
                this.error = true;
                console.error(error);
            } finally {
                this.updating = false;
            }
        });
    }
}
