import { Component, Inject, OnInit } from '@angular/core';
import { WorkOrdersService } from '../../services/work-orders/work-orders.service';
import { WorkOrder } from '../../pages/work-orders/work-orders.component';
import { firstValueFrom } from 'rxjs';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { WorkOrderEvents, WorkOrderStatus } from '../../enums/work-orders';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AuditService } from '../../services/audit/audit.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-contractor-work-order',
    templateUrl: './contractor-work-order.component.html',
    styleUrls: ['./contractor-work-order.component.css'],
})
export class ContractorWorkOrderComponent implements OnInit {
    workOrder!: WorkOrder;
    uploading: boolean = false;
    docsUploading: boolean = false;
    upload$!: any;
    docsUpload$: any;
    progress: number = 0;
    supportingDocProgress: number = 0;
    invoice: File | undefined;
    supportDocs!: File | undefined;
    fileList: string[] = [];
    disable: boolean = false;
    loading: boolean = false;

    constructor(
        public workOrderService: WorkOrdersService,
        public dialog: MatDialog,
        public auditService: AuditService,
        @Inject(MAT_DIALOG_DATA) public data: { workOrder: Partial<WorkOrder> }
    ) {}

    async ngOnInit(): Promise<void> {
        try {
            this.loading = true;
            this.workOrder = await firstValueFrom(
                this.workOrderService.getOrder(
                    this.data.workOrder.orderNumber as string,
                    this.data.workOrder.site as string
                )
            );
            this.fileList =
                (await firstValueFrom(
                    this.workOrderService.getFileList(
                        this.workOrder.orderNumber
                    )
                )) || [];
            this.disable = [
                'Awaiting Payment',
                'Invoice Paid',
                'Awaiting Authorisation',
                'Closed',
            ].includes(this.workOrder.status);
        } catch {
        } finally {
            this.loading = false;
        }
    }

    calcTotal = () =>
        Object.values(this.workOrder.leaseGroups).reduce((a, b) => a + b, 0);

    /**
     * Upload File to AWS, If file does not already exist with name
     *
     * @param file
     */
    uploadSupportingDocs = async (file: File | undefined): Promise<any> => {
        if (!file) return;
        try {
            this.docsUploading = true;

            const url = await firstValueFrom(
                this.workOrderService.getPresignedUrl(
                    this.workOrder.orderNumber + '/supportingDocs/' + file.name,
                    'Put'
                )
            );

            return (this.docsUpload$ = this.workOrderService
                .fileUpload(url, '', file)
                .subscribe(async (completed) => {
                    this.fileList =
                        (await firstValueFrom(
                            await this.workOrderService.getFileList(
                                this.workOrder.orderNumber
                            )
                        )) || [];
                    this.docsUploading = false;
                    this.supportDocs = undefined;
                }));
        } catch {
        } finally {
            this.docsUploading = false;
        }
    };

    /**
     * Cancel an upload in progress
     */
    cancelUpload = (): void => {
        if (this.upload$) this.upload$.unsubscribe();
        this.uploading = false;
    };

    /**
     * Cancel an upload in progress
     */
    cancelUploadDocs = (): void => {
        if (this.docsUpload$) this.docsUpload$.unsubscribe();
        this.docsUploading = false;
    };

    /**
     * * View file
     * @param fileName
     */
    openFile(fileName: string): void {
        const windowRef = window.open();
        firstValueFrom(
            this.workOrderService.getPresignedUrl(fileName, 'Get')
        ).then((url) => {
            if (windowRef) windowRef.location = url;
        });
    }

    /**
     * Delete file for property
     * @param file
     */
    deleteFile(file: string): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: { message: 'Are you sure you want to delete this file?' },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.workOrderService.deleteFile(file).subscribe(async () => {
                    this.fileList = await firstValueFrom(
                        this.workOrderService.getFileList(
                            this.workOrder.orderNumber
                        )
                    );
                    const submitResponse = await firstValueFrom(
                        this.workOrderService.updateOrder(
                            {
                                orderNumber: this.workOrder.orderNumber,
                                status: WorkOrderStatus.Open,
                            },
                            this.workOrder.site
                        )
                    );
                    this.workOrder.status = WorkOrderStatus.Open;
                });
            }
        });
    }

    /**
     * Upload File to AWS, If file does not already exist with name
     *
     * @param file
     */
    uploadFile = async (file: File | undefined): Promise<any> => {
        if (!file) return;
        this.uploading = true;
        const url = await firstValueFrom(
            this.workOrderService.getPresignedUrl(
                this.workOrder.orderNumber + '/invoice.pdf',
                'Put'
            )
        );

        return (this.upload$ = this.workOrderService
            .fileUpload(url, '', file)
            .subscribe(async (event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const eventTotal: number = event.total
                            ? event.total
                            : 0;
                        this.progress = Math.round(
                            (event.loaded / eventTotal) * 100
                        );
                        break;

                    case HttpEventType.Response:
                        this.fileList =
                            (await firstValueFrom(
                                this.workOrderService.getFileList(
                                    this.workOrder.orderNumber
                                )
                            )) || [];
                        const audits = await this.createNewAuditsArray(
                            WorkOrderEvents.InvoiceUploaded
                        );
                        const submitResponse = await firstValueFrom(
                            this.workOrderService.updateOrder(
                                {
                                    orderNumber: this.workOrder.orderNumber,
                                    status: WorkOrderStatus.InvoiceApproval,
                                    audits,
                                },
                                this.workOrder.site
                            )
                        );
                        this.workOrder.status = WorkOrderStatus.InvoiceApproval;
                        this.workOrder.audits = audits;
                        this.invoice = undefined;
                        this.uploading = false;
                        this.progress = 0;
                        break;
                }
            }));
    };

    createNewAuditsArray = async (newEvent: WorkOrderEvents) => {
        let audits = (
            await firstValueFrom(
                this.workOrderService.getOrder(
                    this.workOrder.orderNumber,
                    this.workOrder.site
                )
            )
        )?.audits;
        const newAudit = await this.auditService.createWorkOrderAudit(newEvent);
        return audits ? [...audits, newAudit] : [newAudit];
    };

    supportingFiles = () => {
        return (
            this.fileList.filter((file: string) =>
                file.startsWith(this.workOrder.orderNumber + '/supportingDocs')
            ) || []
        );
    };

    isCorrectType = (fileTypes: string[], file: File): boolean => {
        return fileTypes.includes(file.type);
    };

    protected readonly JSON = JSON;
}
